// Students.js
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css'
import './aboutus.css';
import VideoBackground from '../components/VideoBackground';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Aboutus = () => {
    const dynamicVideoLink = "videos/Sequence 01_2.mp4";
    const pageName = "aboutPage"
    const buttonText = "Home"

    useEffect(() => {
        const elements = document.getElementsByClassName("text-yellow");

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add(".text-dark1");
        }
        const section = document.getElementsByClassName("mainSectionDiv")[0];
        section.classList.add("aboutSectionActive");
    }, []);

    return (
        // <>

        <div className=''>
            {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
            {/* <> */}
            <Navbar />
            <VideoBackground
                videoLink={dynamicVideoLink}
                buttonText={buttonText}
                pageName={pageName}
            />
            <div style={{ height: '100%', paddingTop: '20px' }} className='bg-body-about'>
                <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
                    <div className='content-margin '>
                    
                       
                       
                        <div className='mt-2 pt-3'>
                        <heding className='montser-lrg-bold align-items-center py-4'>Nurturing Your Brain's Health and Vitality: </heding>

                        <p className='align-items-center monster-lrg-normal p-gap ' style={{ paddingTop: '1.5rem' }}>
                        Welcome to Healthy Brain Academy, a place where compassion meets science to support your journey toward lifelong brain health. 
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap ' style={{ paddingTop: '1.5rem' }}>
                        We are committed to using the Virtu Prep neuroscience-based platform to not only enhance cognitive function but also to provide care and understanding for every individual looking to improve their brain resilience against aging. 
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap ' style={{ paddingTop: '1.5rem' }}>
                        Our goal is to offer a nurturing environment where anyone can learn to protect and enhance their mental capabilities.

                        </p>
           
                        <heding className='montser-lrg-bold align-items-center py-4'>Our Vision: A Compassionate Approach to Cognitive Wellness</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        At Healthy Brain Academy, we envision a future where cognitive wellness is accessible to all, guided by empathy and the latest scientific advancements.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        We understand the concerns that come with aging and the desire to maintain mental sharpness. Therefore, we dedicate ourselves to creating a supportive community where individuals can find solace and strength in their pursuit of cognitive vitality.

                        </p>
                        

                        <heding className='montser-lrg-bold align-items-center py-4'> What We Provide: Personalized, Heartfelt Support for Cognitive Growth</heding>

                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Empathetic, Science-Driven Programs: Our brain training programs are rooted in neuroscience, designed with care to stimulate cognitive improvement while being mindful of individual experiences and needs.
                        </p>
                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Customized for You: We recognize the uniqueness of each brain and tailor our training to fit your specific goals, offering a personal touch to cognitive enhancement.
                        </p>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Holistic Cognitive Care: Focusing on key cognitive areas such as memory, attention, and problem-solving, our programs are crafted to enrich your life and empower you with stronger mental capabilities.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap'>
                        For educators and administrators, this wealth of data opens up new possibilities for tailored teaching strategies, allowing them to become architects of personalized learning experiences. Virtu Prep also plays an active role in professional development, offering educators and administrators workshops, webinars, and training to keep them abreast of the latest in teaching strategies and technologies.
                        </p>
                       


                        <heding className='montser-lrg-bold align-items-center py-4'>Embark on Your Path to Enhanced Brain Health:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        The journey to maintaining and enhancing your cognitive function is a profound one, and at Healthy Brain Academy, we walk this path with you. Our compassionate approach ensures that you feel supported and understood every step of the way.
                        </p>
                

                        <heding className='montser-lrg-bold align-items-center py-4'>Join the Healthy Brain Academy:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Discover the difference a nurturing, science-based brain training program can make in your life. Welcome to a community that cares deeply about your cognitive wellness. Welcome to Healthy Brain Academy.
                        </p>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Click 'Meet Us' to learn more about our unique approach and begin your journey toward sustained cognitive health and vitality.
                        </p>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
         
        </div>
      
       


    );
};

export default Aboutus;

