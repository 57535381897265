// VideoBackground.js
import React from 'react';
import '../global.css';
import './videobackground.css'
import { useState, useRef } from 'react';

const VideoBackground = ({ videoLink, buttonText, pageName })  => {
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(true);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setPlaying(!isPlaying);
    }
  };

  const handleJoinClick = () => {
    const destinationUrl = buttonText === "Home" ? "/" : "/about-us";
    window.location.href = destinationUrl;
  };
  
  

  return (
    <div className="video-background" id="mainPage">
      <video ref={videoRef} autoPlay loop muted playsInline>
        <source src={videoLink} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay">

    </div >
    <div className='vp-container video-bg-1 z-index-9 mb-auto'>
    <div className="video-text text-yellow heading-left gap-3 gap-md-5 d-flex">
        {/* <h1>Virtu-AI</h1> */}
        <div className='logo-container d-inline-flex align-items-center gap-2 maxw-265 w-100 me-xl-5 me-0 d-none d-lg-block'></div>
        <div>
        {pageName === "mainPage" ? (
        <h1 className='custom-large-heading light-col common-textshadow mobile-heading montser-mdm-italic ft-5vh'>Brains age<br></br> <br></br> <br></br>  but your mind’s strength can endure </h1>
        ) : (  <h1 className='custom-large-heading light-col common-textshadow mobile-heading montser-lrg-LightItalic'><br></br> <br></br> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  </h1>
          )}
        </div>     

      
       </div>
      
      
    </div>
    <div className='vp-container d-flex flex-row gap-4 mb-5 pos-rel z-index-9'>
      <div className="flex-grow-1 d-flex flex-column gap-4">
        {pageName === "mainPage" ? (
            <h1 className='text-sm light-col common-textshadow mobile-heading'>
              Your elevated learning environment
            </h1>
          ) : (
            <h1 className='custom-large-heading light-col common-textshadow mobile-heading'>
              About Us - Healthy Brain Academy
            </h1>
          )}
          <div className='about'>
          <button type="button" className="outline-lightgreen py-0 rounded-top rounded-0 btn btn-outline-light montser-mdm-2 " onClick={handleJoinClick}>
          {buttonText}
          </button>
          </div>
          </div>
            <div className='d-flex align-items-end'>
            <div className="video-controls">
          <button onClick={togglePlayPause} className='rounded-top rounded-0 video-button  montser-mdm-2'>
          {isPlaying ? (
                <>
                  <i class="demo-icon icon-pause video-btn-icon-size"></i> 
                </>
              ) : (
                <>
                  <i class="demo-icon icon-play video-btn-icon-size"></i>                 </>
              )}
          </button>
        </div>
            </div>
        </div>
    </div>
  );
};

export default VideoBackground;
